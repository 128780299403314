/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {
    Box, Typography, Grid, Card, CardContent, CardMedia,
    Button, CardActions, Avatar, Stack
} from '@mui/material';
import RobotIcon from '@mui/icons-material/Android'; // Replace with an appropriate icon
import BusinessIcon from '@mui/icons-material/Business';
import ChatIcon from '@mui/icons-material/Chat';
import KnowledgeBaseIcon from '@mui/icons-material/LibraryBooks';
import { use } from 'marked';
import { useTheme } from '@mui/material/styles';
import { useChat } from '../context/ChatContext';
import useEmblaCarousel from 'embla-carousel-react';
import { PrevButton, NextButton, usePrevNextButtons } from '../plugins/embla/EmblaCarouselArrowButtons';
import '../plugins/embla/embla.css'
import { useAuth } from '../context/AuthContext';
import { ReactTyped } from 'react-typed';

import WelcomeCard from './cards/WelcomeCard';

const cardData = [
    {
        image: "/support.png",
        icon: RobotIcon,
        title: "24/7 Customer Support",
        content:
            "Our chatbot is available around the clock to provide instant answers to your customer inquiries, improving satisfaction and reducing support costs.",
    },
    {
        image: "/data.png",
        icon: BusinessIcon,
        title: "Data-Driven Insights",
        content:
            "Vertex AI analyzes chat interactions to uncover valuable insights, trends, and patterns, enabling data-driven decision-making for your business.",
    },
    {
        image: "/secure.png",
        icon: ChatIcon,
        title: "Personalized Interactions",
        content:
            "Our chatbot tailors responses to individual users, providing a personalized experience that enhances engagement and builds stronger customer relationships.",
    },
    {
        image: "/knowledge.png",
        icon: KnowledgeBaseIcon,
        title: "Enterprise Knowledge",
        content:
            "Get accurate and relevant answers instantly. Our chatbot is connected to your company's knowledge base, providing access to the information you need, when you need it.",
    },
];


function WelcomeMessage() {
    const theme = useTheme();
    const auth = useAuth();
    const { isIntroChat, setIsIntroChat, hasUserContext } = useChat();
    const [emblaRef, emblaApi] = useEmblaCarousel({ align: 'start' });

    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi)

    // Conditionally render the greeting based on user context
    const firstName = auth.user.displayName.split(' ')[0];
    let greetingMessage = `Welcome to the AI Assistant ${firstName || auth.userName}!`;
    if (hasUserContext && auth.userName) {
        greetingMessage = `Welcome back, ${firstName || auth.userName}!`;
    }


    return (
        <Box sx={{ maxWidth: 1500, mx: "auto" }}>
            <Box sx={{ mb: 10, ml: 5, textAlign: "left" }}>
                {/* Greeting Section, aligned left */}
                <Typography
                    variant="h2"
                    component="h1"
                    gutterBottom
                    sx={{
                        backgroundImage:
                            theme.palette.mode === "light"
                                ? "linear-gradient(to right, #4285F4, #EA4335)"
                                : "linear-gradient(to right, #42a5f5, #f48fb1)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontWeight: 'bold',
                    }}
                >
                    <ReactTyped strings={[greetingMessage]} typeSpeed={40} />
                </Typography>

                {!hasUserContext && (
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="h6" color="text.secondary" sx={{ opacity: 0.7 }}>
                            Tell me a bit about yourself and your company to help me get started.
                        </Typography>
                        <Button
                            onClick={() => setIsIntroChat(true)}
                            variant="contained"
                            sx={{
                                borderRadius: "20px",
                                fontWeight: "bold",
                                // Hover effect for the button
                                "&:hover": {
                                    backgroundColor: theme.palette.primary.dark, // Or any desired hover color
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add a subtle shadow on hover
                                },
                            }}
                        >
                            Start Personalizing
                        </Button>
                    </Stack>
                )}
            </Box>
            <Box
                sx={{
                    mt: 10,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "1000px",
                    margin: "0 auto", // Center horizontally
                }}
            >


                <Typography variant="h3" component="h1" fontWeight="bold" gutterBottom sx={{ mb: 2, color: theme.palette.text.secondary }} >
                    Elevate Your
                </Typography>

                {/* Modified second title line with background color. Use the Google blue as the background color */}
                <Typography
                    variant="h3"
                    component="h2"
                    sx={{
                        mb: 4,
                        backgroundColor: '#4285F4', // Use a solid color background (green in this example)
                        color: 'white',
                        padding: '4px 8px', // Add padding for better visual separation
                        borderRadius: '4px' // Add rounded corners
                    }}
                >
                    Conversations
                </Typography>

                <Typography variant="h4" component="h3" color="textSecondary" gutterBottom sx={{ mb: 8 }}>
                    Introducing Your Enterprise AI Assistant
                </Typography>


                <Box sx={{
                    width: '90%',  // Use a percentage width for responsiveness
                    maxWidth: 1200, // Set a maximum width if needed
                    overflow: 'hidden'
                }}>
                    <div className="embla" ref={emblaRef}>
                        <div className="embla__container" style={{ display: 'flex' }}>
                            {/* Map over cardData to render WelcomeCard components */}
                            {cardData.map((card, index) => (
                                <div className="embla__slide" style={{ flex: "0 0 33.3333%" }} key={index}>
                                    <WelcomeCard {...card} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="embla__controls">
                        <div className="embla__buttons">
                            <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                            <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
                        </div>
                    </div>
                </Box>



            </Box >
        </Box>

    );
}

export default WelcomeMessage;
