/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { createContext, useState, useEffect, useContext } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut } from 'firebase/auth';
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from '../config/firebaseConfig';


// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const auth = getAuth();
const provider = new GoogleAuthProvider();

export const AuthContext = createContext({
    user: null,
    signIn: () => Promise.resolve(),
    handleSignOut: () => Promise.resolve(),
    isLoading: true,
    avatarUrl: '/user-avatar.png',
    analytics: analytics
});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setIsLoading(false);
        });

        return unsubscribe;
    }, []);

    const signIn = async () => {
        try {
            await signInWithPopup(auth, provider);
            logEvent(analytics, 'login', { method: 'Google' });
        } catch (error) {
            console.error('Error signing in:', error);
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            setUser(null);
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    // Provide the user image url or the default image URL if none available
    const avatarUrl = user?.photoURL || '/user-avatar.png';


    return (
        <AuthContext.Provider value={{ user, signIn, handleSignOut, isLoading, avatarUrl, analytics }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom useAuth hook (Exporting)
export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
}


