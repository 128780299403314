/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useContext } from 'react';
import { useTheme, List, ListItem, Divider, Typography, Box, Button, Avatar, Tooltip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import ConversationsList from './ConversationsList';
import ModelAgentDropdown from './ModelAgentDropdown';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';



function DrawerContent({ drawerOpen, toggleDrawer, startNewChat, handleOpenSetupModal, conversationsWithMessages, setChatSessionId, chatSessionId }) {
    const theme = useTheme();



    return (
        <>
            <Box sx={{
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                height: 64,
                display: 'flex',
                alignItems: 'center',
                px: 2, // Padding on the sides
                justifyContent: drawerOpen ? 'space-between' : 'center', // Center the menu icon when collapsed
            }}>
                <IconButton onClick={toggleDrawer} sx={{ color: 'white' }}>
                    {drawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                </IconButton>
                {drawerOpen && ( // Conditionally render the title
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        My Assistant
                    </Typography>
                )}
            </Box>
            <List sx={{ pt: 2, pb: 2 }}>
                {/* New Chat Button (directly in DrawerContent) */}
                <ListItem disablePadding>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
                        <Button onClick={startNewChat} sx={{}}>
                            <Avatar sx={{ bgcolor: '#4285F4', color: 'white', '&:hover': { backgroundColor: '#3367d6' }, }}>
                                <AddIcon />
                            </Avatar>
                            {drawerOpen && <Typography sx={{ ml: 1, color: theme.palette.text.primary, '&:hover': { color: '#3367d6' }, }} variant="body">New Chat</Typography>}
                        </Button>
                    </Box>
                </ListItem>


                {/* Conditionally render the rest of the list */}
                {drawerOpen && (
                    <>
                        {/* Conversations List */}
                        <ConversationsList drawerOpen={drawerOpen} conversationsWithMessages={conversationsWithMessages} setChatSessionId={setChatSessionId} chatSessionId={chatSessionId} />
                    </>
                )}


            </List>
            <List sx={{ marginTop: `auto`, mb: 5 }}>
                {/* Setup Button (directly in DrawerContent) */}
                <ListItem disablePadding sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title={drawerOpen ? "Setup" : ""} placement="right" arrow>
                            <Box>
                                <IconButton onClick={handleOpenSetupModal} sx={{ color: 'gray' }}>
                                    <SettingsIcon />
                                </IconButton>
                                {drawerOpen && <Typography variant="caption">{'Settings'}</Typography>}
                            </Box>
                        </Tooltip>
                    </Box>
                </ListItem>
            </List>
        </>
    );
}

export default DrawerContent;
