/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Card, Box, Typography, IconButton, useTheme, CardMedia } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import VideoFileIcon from '@mui/icons-material/VideoFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';

// Helper function to format file size
function formatFileSize(size) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
        size /= 1024;
        i++;
    }
    return size.toFixed(1) + ' ' + units[i];
}

const FileCard = ({ index, file, onRemove }) => {
    const theme = useTheme();

    const iconForFileType = (fileType) => {
        const type = fileType.split('/')[0];
        switch (type) {
            case 'image':
                return null;
            case 'video':
                return <VideoFileIcon />;
            case 'audio':
                return <AudioFileIcon />;
            default:
                return <FilePresentOutlinedIcon />;
        }
    };

    return (
        <Card
            sx={{
                display: 'flex',
                m: 1,
                bgcolor: theme.palette.grey.light,
                border: '1px solid',
                borderColor: theme.palette.grey.medium,
                borderRadius: 2,
                boxShadow: 1,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                {file.type.startsWith("image/") ? (
                    <CardMedia
                        component="img"
                        height="40"
                        image={URL.createObjectURL(file)}
                        alt={file.name}
                    />
                ) : (
                    iconForFileType(file.type)
                )}
            </Box>

            <Box sx={{ flexGrow: 1, p: 1 }}>
                <Typography variant="body2" component="div" color={theme.palette.text.primary}>
                    {file.name}
                </Typography>
                <Typography variant="caption" color={theme.palette.text.secondary}>
                    {formatFileSize(file.size)}
                </Typography>
            </Box>

            <IconButton aria-label="remove file" size="small" onClick={() => onRemove(index)} sx={{ color: theme.palette.text.secondary }}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </Card>
    );
};

export default FileCard;