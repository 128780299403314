/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState, useEffect, useContext } from 'react';
import { logEvent } from 'firebase/analytics';
import { sendChatMessage, summarizeConversation, fetchSourceList, fetchAgentList, suggestQuestions } from './backend/vertexAI';
import ChatInterface from './components/ChatInterface';
import {
  Box, Button, Drawer, List, ListItem, ListItemButton, Tooltip, Typography, Divider,
  FormControl, InputLabel, Select, MenuItem, Avatar, AppBar, Toolbar, IconButton, ListSubheader,
  Modal, Menu, useMediaQuery, Hidden, Paper
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout'; // Import logout icon
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';

import { v4 as uuidv4 } from 'uuid';


import {
  // ... (other imports from '@mui/material')
  collection,
  getDocs,
  query,
  orderBy, updateDoc,
  setDoc, getDoc, doc, limit
} from "firebase/firestore";

import DrawerContent from './components/DrawerContent';
import './App.css'; // Import your CSS for styling
import modelOptions from './config/modelOption';
import SetupContent from './components/Setup'; // Import the new component
import { useAuth } from './context/AuthContext';
import { useDb } from './context/DbContext';
import { ChatContext } from './context/ChatContext';
import IntroEndModal from './components/modals/IntroEndModal';



function ChatApp() {
  const [messages, setMessages] = useState({});
  const [userInput, setUserInput] = useState('');
  const [smartQuestion, setSmartQuestion] = useState('');
  const [conversationTitleList, setConversationTitleList] = useState([]);


  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false); // State to control drawer open/close
  // State for model selection
  const [selectedOptionId, setSelectedOptionId] = useState('gemini-1.5-pro-001');
  const [sessionTitles, setSessionTitles] = useState({}); // Store titles per session ID

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [setupModalOpen, setSetupModalOpen] = useState(false); // State for modal
  const [introCompleteModalOpen, setIntroCompleteModalOpen] = useState(false);

  const theme = useTheme(); // Access the ThemeContext

  const [isInitialized, setIsInitialized] = useState(false); // State to track initialization

  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check for mobile screen size


  const auth = useAuth()
  const { user, analytics } = auth;
  const dbContext = useDb();
  const db = dbContext.db;

  const { chatSessionId, setChatSessionId, showWelcome, setShowWelcome,
    setSelectedSource, setSourceList, selectedSource, sourceList,
    isIntroChat, setIsIntroChat, hasUserContext, userContext, setUserContext } = useContext(ChatContext);

  //Initialization Function
  useEffect(() => {
    const initialize = async () => {
      if (user) { // Check if user is logged in
        const userRef = doc(db, 'users', user.uid);

        try {
          const userDoc = await getDoc(userRef);

          if (!userDoc.exists()) { // User does not exist, create new
            console.log("User not found, creating new user document.");
            await setDoc(userRef, {
              selectedModelId: selectedOptionId,
              isDarkMode: false,
              createdAt: new Date()
            });
          } else {  // User exists, load settings
            const userData = userDoc.data();
            console.log("User exists, loading context");
            console.log(userData);
            setSelectedOptionId(userData.selectedModelId || 'gemini-1.0-pro-002'); // Default if not found
            if (userData.context) {
              setUserContext(userData.context)
            } else {
              console.log('Setting user context to empty object')
              setUserContext({})
            }
          }
        } catch (error) {
          console.error('Error initializing user data:', error);
        }
      }
      setIsInitialized(true); // Indicate initialization complete
    };

    initialize();
  }, [user]);


  useEffect(() => {
    // Fetch conversations and source list when user logs in or changes
    const fetchConversations = async () => {
      if (user) { // Check if user is logged in
        const chatsCollection = collection(db, `users/${user.uid}/chats`); // Reference to the user's chats collection
        const q = query(chatsCollection, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        const newMessages = {};
        const newSessionTitles = {};
        querySnapshot.forEach(doc => {
          newMessages[doc.id] = doc.data().conversation || [];
          newSessionTitles[doc.id] = doc.data().title;
        });
        setMessages(newMessages);
        setSessionTitles(newSessionTitles);

        startNewChat();
      }
    };
    const getSourceList = async () => {
      const source = await fetchSourceList();
      setSourceList(source);
    };

    Promise.all([fetchConversations(), getSourceList()]); // Fetch conversations and source list in parallel
  }, [user]);

  const handleFileUpload = () => {


  };





  // Function to handle opening and closing the setup modal
  const handleOpenSetupModal = () => {
    setSetupModalOpen(true);
  };

  const handleCloseSetupModal = () => {
    setSetupModalOpen(false);
  };

  // Function to toggle the drawer
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };



  const startNewChat = async () => {
    setIsIntroChat(false)
    setShowWelcome(true);
    const newChatSessionId = uuidv4().slice(0, 8);
    setChatSessionId(newChatSessionId);
    const newMessages = {};
    newMessages[newChatSessionId] = [];

    setMessages(prevMessages => ({
      ...prevMessages,
      [newChatSessionId]: []
    }));
    setChatSessionId(newChatSessionId);

  };


  useEffect(() => {
    if (smartQuestion) {
      handleSendMessage();
    }
  }, [smartQuestion]);


  const handleCloseIntroCompleteModal = () => {
    setIntroCompleteModalOpen(false);
  };

  const handleStartIntroduction = async () => {
    let receivedChatSessionId = chatSessionId;
    let fullResponse;
    let response;
    let newSessionMessages = [];
    let conversationRef;
    let lastModifiedTimestamp;
    let inputText = smartQuestion || userInput;
    let userMessage = { text: inputText };

    logEvent(analytics, 'intro_conversation');
    if (showWelcome) {
      setShowWelcome(false);
      inputText = 'Hello';

      const newChatSessionId = uuidv4().slice(0, 8);
      setChatSessionId(newChatSessionId);
      receivedChatSessionId = newChatSessionId;

      setMessages(prevMessages => ({
        ...prevMessages,
        [newChatSessionId]: []
      }));

    }

    if (inputText.trim() === '' && smartQuestion.trim() === '')
      return

    setIsWaitingForResponse(true);

    try {

      [receivedChatSessionId, fullResponse] = await sendChatMessage({ text: inputText }
        , selectedOptionId, receivedChatSessionId, selectedSource, sourceList, isIntroChat, user.uid); // Use receivedChatSessionId

      response = fullResponse.response.text;
      let sessionMessages = messages[receivedChatSessionId] || []; // Use receivedChatSessionId
      newSessionMessages = [...sessionMessages, { text: inputText, sender: 'user' }];

      setChatSessionId(receivedChatSessionId);
      newSessionMessages.push({ text: response, sender: 'bot', });
      logEvent(analytics, 'bot_interactions', { question_length: inputText.length, response_length: response.length });
      setMessages(prevMessages => ({
        ...prevMessages,
        [receivedChatSessionId]: newSessionMessages // Use receivedChatSessionId
      }));

      if (fullResponse.intro_complete) {
        // update user context      
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();
        const userContext = fullResponse.context || {};
        await updateDoc(userRef, { context: userContext });
        setUserContext(userContext);
        setIsIntroChat(false);
        setIntroCompleteModalOpen(true);
      }

    } catch (error) {
      console.error('Error sending chat message:', error);
      newSessionMessages.push({
        text: "There was an error getting a response. Please try again.",
        sender: "bot",
      });
    } finally {
      setIsWaitingForResponse(false);

      // Clear input and file after sending
      setSmartQuestion("");
      setUserInput("");
      setSelectedFiles(null);
    }



  };

  useEffect(() => {
    if (isIntroChat) {
      handleStartIntroduction();
    }
  }, [isIntroChat]);

  const handleSendMessage = async () => {

    if (isIntroChat) {
      handleStartIntroduction();
    } else {
      handleChatMessage();
    }
  }



  const handleChatMessage = async () => {
    let receivedChatSessionId = chatSessionId;
    let fullresponse;
    let response;
    let newSessionMessages = [];
    let conversationRef;
    let lastModifiedTimestamp;
    const newChatSessionId = uuidv4().slice(0, 8);
    if (userInput.trim() === '' && smartQuestion.trim() === '') return;

    setShowWelcome(false);
    setIsWaitingForResponse(true);

    // Display the user's question and files immediately
    const userMessage = { text: smartQuestion || userInput, sender: 'user' };
    if (selectedFiles) {
      const base64Files = await Promise.all(selectedFiles.map(file => {
        console.log('File:', file);
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
        });
      }));
      userMessage.files = selectedFiles;
      userMessage.base64Files = base64Files;
    }
    newSessionMessages.push(userMessage); // Include files in the message
    setMessages(prevMessages => ({
      ...prevMessages,
      [receivedChatSessionId]: [...(prevMessages[receivedChatSessionId] || []), ...newSessionMessages]
    }));
    // retrieving the conversation document from firestore
    // if doc exists, update it, else create it
    conversationRef = doc(collection(db, `users/${user.uid}/chats/`), receivedChatSessionId)
    let conversationDoc = await getDoc(conversationRef);
    let conversation = []
    if (!conversationDoc.exists()) {
      try {
        conversationDoc = await setDoc(conversationRef, { // Use the new document reference with setDoc
          title: 'New Chat',
          conversation: [],
          createdAt: new Date()
        });
      } catch (error) {
        console.error('Error creating new chat:', error);
      }
    } else {
      // extract the conversation array from the document data
      conversation = conversationDoc.data().conversation || [];
    }

    setShowWelcome(false);
    setIsWaitingForResponse(true);

    try {
      const inputText = smartQuestion || userInput;
      const userMessage = { text: inputText }
      // Move base64 encoding here
      if (selectedFiles) {
        const base64Files = await Promise.all(selectedFiles.map(file => {
          console.log('File:', file);
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
          });
        }));
        userMessage.files = selectedFiles;
        userMessage.base64Files = base64Files;
      }


      [receivedChatSessionId, fullresponse] = await sendChatMessage(userMessage, selectedOptionId, receivedChatSessionId,
        selectedSource, sourceList, isIntroChat, userContext, user.uid);
      response = fullresponse.response;
      let sessionMessages = messages[receivedChatSessionId] || []; // Use receivedChatSessionId
      newSessionMessages = [...sessionMessages, { text: inputText, sender: 'user', files: response.files }];

      setChatSessionId(receivedChatSessionId);

      newSessionMessages.push({ text: response.text, sender: 'model', citations: fullresponse.citations || [] });

      const tokens = response?.response?.usage_metadata?.total_token_count || 0;
      // Add the user question and the bot response to the conversation array
      console.log('Conversation:', fullresponse);
      conversation.push({ text: inputText, sender: 'user', files: response.files || [] });
      conversation.push({ text: response.text, sender: 'model', tokens: tokens, citations: fullresponse?.citations || [] });
      logEvent(analytics, 'bot_interactions', { question_length: inputText.length, response_length: response.text.length });
      lastModifiedTimestamp = new Date();

      console.log('Conversation:', conversation);
      // Update the conversation array in the document
      await updateDoc(conversationRef, { conversation, lastModified: lastModifiedTimestamp });

      setIsWaitingForResponse(false);
    } catch (error) {
      console.error('Error:', error);
      setIsWaitingForResponse(false);
      newSessionMessages.push({
        text: 'There was an error getting a response. Please try again.',
        sender: 'model'
      });
    }

    setMessages(prevMessages => ({
      ...prevMessages,
      [receivedChatSessionId]: newSessionMessages // Use receivedChatSessionId
    }));

    // Clear input and file after sending
    setSmartQuestion('');
    setUserInput('');
    setSelectedFiles(null);

    let summary = sessionTitles[receivedChatSessionId] || '';
    if (newSessionMessages.length <= 5) { // 10 messages = 5 turns
      try {
        // Filter to only include user messages (sender: 'user')
        const userQuestions = newSessionMessages.filter(msg => msg.sender === 'user');
        summary = await summarizeConversation(userQuestions);
        console.log('Summary:', summary);
        if (conversationRef) {
          await updateDoc(conversationRef, { title: summary });
        }

      } catch (error) {
        console.error('Error summarizing conversation:', error.message);
      }
    }

    //Rerank the sessionTitles to have the most recent session at the top
    setSessionTitles(prevTitles => {
      const updatedSession = {
        [receivedChatSessionId]: summary,
      };

      // Remove the receivedChatSessionId from the rest of the titles
      const restOfTitles = Object.fromEntries(
        Object.entries(prevTitles).filter(([sessionId]) => sessionId !== receivedChatSessionId)
      );

      // Combine the updated session with the rest of the titles
      return { ...updatedSession, ...restOfTitles };
    });


  };



  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  useEffect(() => {
    console.log("Updating sessionTitles");
    console.log("sessionTitles:", sessionTitles);

    // Directly update conversationTitleList with sorted sessionTitles
    const sortedTitles = Object.entries(sessionTitles)
      .map(([sessionId, title]) => ({ sessionId, title }));

    console.log(sortedTitles)
    setConversationTitleList(sortedTitles);
  }, [sessionTitles]);


  const handleUserInputChange = (event) => {
    event.preventDefault();
    setUserInput(event.target.value);
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    // Call your signOut function from AuthContext
    auth.handleSignOut();
  };

  return (


    <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', }}>
      <Hidden smDown>
        <Drawer
          variant={isMobile ? "temporary" : "permanent"} // Change to 'temporary' on mobile
          anchor={isMobile ? "left" : "left"}
          open={drawerOpen}
          sx={{
            width: drawerOpen ? 240 : 80, // Adjust width for collapsed/expanded states
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerOpen ? 240 : 80,
              boxSizing: 'border-box',
              overflowX: 'hidden',
              bgcolor: theme.palette.background.light, // Light background for sidebar
              borderRight: 'none', // Remove default border
            },
          }}
        >

          <DrawerContent
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            startNewChat={startNewChat}
            conversationsWithMessages={conversationTitleList}
            setChatSessionId={setChatSessionId}
            chatSessionId={chatSessionId}
            handleOpenSetupModal={handleOpenSetupModal}
          />


        </Drawer>
      </Hidden>

      <Box sx={{ flexGrow: 1, bgcolor: theme.palette.background.default }}>
        <AppBar position="sticky">
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            {/* Hamburger menu on mobile only */}
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>
                Source:
              </Typography>
              <FormControl variant="standard" sx={{ minWidth: isMobile ? 120 : 200, '& .MuiInputBase-root': { borderRadius: 2, backgroundColor: '#f0f0f0' } }}>

                <Select
                  labelId="source-select-label"
                  id="source-select"
                  value={selectedSource || ''}
                  onChange={(e) => setSelectedSource(e.target.value)}
                  sx={{ paddingLeft: 1, paddingRight: 1, color: theme.palette.text.reversed }}
                >
                  <MenuItem key='nosource' value=''>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                      <Typography variant="inherit" sx={{ marginLeft: 1 }}>
                        -- None --
                      </Typography>
                    </div>
                  </MenuItem>

                  {/* Render options with ListSubheader and Divider (using flatMap) */}
                  {[
                    { type: 'other', label: 'Other Sources' },
                    { type: 'store', label: 'Data Sources' },
                    { type: 'agent', label: 'Agents' },
                  ].flatMap(({ type, label }) => { // Use flatMap to create a single array

                    const filteredSources = sourceList.filter(source => source.type === type);
                    if (filteredSources.length === 0) {
                      return []; // Return an empty array if no sources of this type
                    }

                    return [
                      // ListSubheader (only if there are filtered sources)
                      <ListSubheader key={`${type}-header`}>{label}</ListSubheader>,

                      // MenuItems for each source
                      ...filteredSources.map(source => (
                        <MenuItem key={source.id} value={source.id}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {source.icon}
                            <Typography variant="inherit" sx={{ marginLeft: 1 }}>
                              {source.name}
                            </Typography>
                          </div>
                        </MenuItem>
                      )),

                      // Divider (only if it's not the last section)
                      type !== 'agent' ? <Divider key={`${type}-divider`} /> : null,
                    ];
                  })}
                </Select>
              </FormControl>
            </Box>

            {isMobile && (
              <Tooltip title="New Chat">
                <IconButton color="inherit" onClick={startNewChat}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}

            {/* Space between components */}
            <Box sx={{ flexGrow: 1 }} />


            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar src={auth.avatarUrl} sx={{ bgcolor: '#4285F4', color: 'white' }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* Logout Option */}
                <MenuItem key="logout" onClick={handleLogout}>
                  <LogoutIcon sx={{ mr: 1 }} /> Logout
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <ChatInterface
          messages={messages}
          onSendMessage={handleSendMessage}
          setUserInput={setUserInput}
          userInput={userInput}
          onUserInputChange={handleUserInputChange}
          smartQuestion={smartQuestion}
          setSmartQuestion={setSmartQuestion}
          selectedSession={chatSessionId}
          isWaitingForResponse={isWaitingForResponse}
          modelOptions={modelOptions}
          selectedModelId={selectedOptionId}
          handleFileUpload={handleFileUpload}
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
          isDrawerOpen={drawerOpen}
          shouldShowWelcome={showWelcome}

        />
      </Box>
      {/* Drawer as Temporary for Mobile */}
      {
        isMobile && ( // Show Drawer only on mobile
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer}
            sx={{
              '& .MuiDrawer-paper': {
                width: 240, // Adjust width for temporary drawer
                boxSizing: 'border-box',
              },
            }}
          >
            <DrawerContent
              drawerOpen={drawerOpen}
              toggleDrawer={toggleDrawer}
              startNewChat={startNewChat}
              modelOptions={modelOptions}
              selectedOptionId={selectedOptionId}
              setSelectedOptionId={setSelectedOptionId}
              conversationsWithMessages={conversationTitleList}
              setChatSessionId={setChatSessionId}
              chatSessionId={chatSessionId}
              handleOpenSetupModal={handleOpenSetupModal}
            />

          </Drawer>
        )
      }
      <Modal
        open={setupModalOpen}
        onClose={handleCloseSetupModal}
        aria-labelledby="setup-modal-title"
        aria-describedby="setup-modal-description"
      >
        <div>
          <SetupContent
            selectedOptionId={selectedOptionId}
            setSelectedOptionId={setSelectedOptionId}
            handleCloseSetupModal={handleCloseSetupModal}
          /> {/* Pass the necessary props */}
        </div>
      </Modal>
      <IntroEndModal
        open={introCompleteModalOpen}
        onClose={() => {
          handleCloseIntroCompleteModal();
          startNewChat();
        }}
        onStartNewChat={() => {
          handleCloseIntroCompleteModal();
          startNewChat();
        }}
      />
    </Box >


  );
}

export default ChatApp;
