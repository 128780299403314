/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */


import React, { createContext, useState, useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import { lightTheme, darkTheme } from '../theme';

import { useDb } from './DbContext';
import { useAuth } from './AuthContext';
import { doc, getDoc } from 'firebase/firestore';

// Create the theme context
export const ThemeContext = createContext({
    theme: lightTheme, // Default theme
    isDarkMode: false,
    toggleColorMode: () => { },
    isLoading: true // Expose isLoading in the context
});

// Theme provider component
export const CustomThemeProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [mode, setMode] = useState('light');
    const { user, isLoading: authLoading } = useAuth();
    const { db } = useDb();

    useEffect(() => {
        const fetchThemePreference = async () => {
            try {
                setIsLoading(true); // Start in loading state
                if (user) { // Check if user is logged in
                    const userRef = doc(db, 'users', user.uid);

                    try {
                        const userDoc = await getDoc(userRef);
                        if (userDoc.exists()) {
                            const isDarkMode = userDoc.data().isDarkMode || false; // Default to light mode
                            setMode(isDarkMode ? 'dark' : 'light'); // Set initial mode from Firestore
                        }
                    } catch (error) {
                        console.error('Error fetching theme preference:', error);
                    }
                } else {
                    // If no user is logged in, set the default mode to light
                    setMode('light');
                }
            } catch (error) {
                console.error('Error fetching theme preference:', error);
            } finally {
                setIsLoading(false);
            }
        };
        if (user || authLoading == false) {
            fetchThemePreference();
        }

    }, [user, db, authLoading]);

    const theme = mode === 'dark' ? darkTheme : lightTheme;

    const isDarkMode = mode === 'dark' ? true : false;


    const toggleColorMode = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };

    return (
        <ThemeContext.Provider value={{ theme, isDarkMode, toggleColorMode, isLoading }}>
            {/* Note the inclusion of isLoading in the context value */}
            {React.Children.map(children, (child) =>
                React.cloneElement(child, { theme, isDarkMode, toggleColorMode, isLoading })
            )}
        </ThemeContext.Provider>
    );
};