/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import axios, * as others from 'axios';
import modelOptions from '../config/modelOption';
import config from '../config/apiService';

import GoogleIcon from '@mui/icons-material/Google';
import { SmartToy, LibraryBooks } from '@mui/icons-material';



const serverurl = config.url.API_URL


async function sendChatMessage(message, selectedOptionId, chatSessionId, selectedSourceId, sourceList, isIntroChat, userContext, userId) {
    try {
        console.log('Sending message to the chat service:', message, selectedOptionId, chatSessionId);
        // Find the source type based on selectedSourceId in sourceList
        const sourceType = sourceList.find(source => source.id === selectedSourceId)?.type || null;

        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('prompt', message.text);
        formData.append('chat_session_id', chatSessionId);
        formData.append('source_id', selectedSourceId);
        formData.append('source_type', sourceType);
        formData.append('is_intro_chat', isIntroChat);
        formData.append('user_context', userContext);
        // Send the entire model object as JSON
        formData.append('model', JSON.stringify({ 'id': selectedOptionId, 'model': modelOptions[selectedOptionId] }))
        if (message.files) {
            message.files.forEach((file, index) => {
                formData.append("files", file);
            });
        }

        const response = await axios.post(`${serverurl}/chat`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data' // Important for FormData
            }
        });

        if (response.status !== 200) {
            throw new Error('Failed to get a response from the chat service.');
        }

        // Update the chat session ID if provided by the service
        chatSessionId = response.data.chat_session_id || chatSessionId;
        console.log('Received response with chat session ID:', chatSessionId);
        console.log('Received response from the chat service:', response.data.response);
        // Return the updated chat session ID and the response from the service
        return [chatSessionId, response.data]
    } catch (error) {
        console.error('Error sending message to the chat service:', error.message);
        throw error;
    }
}

async function suggestQuestions(conversationHistory) {
    try {
        const response = await axios.post(`${serverurl}/suggestQuestions`, {
            'conversation': conversationHistory // Send the conversation history to the backend
        });

        if (response.status === 200) {
            return response.data.questions || []; // Return suggested questions or an empty array
        } else {
            throw new Error('Failed to get suggested questions.');
        }
    } catch (error) {
        console.error('Error suggesting questions:', error.message);
        return []; // Return an empty array in case of an error
    }
}

async function summarizeConversation(messages) {
    try {
        const response = await axios.post(`${serverurl}/summarize`, {
            conversation: messages.map(msg => msg.text).join('\n') // Combine messages into a single string
        });

        if (response.status === 200) {
            return response.data.summary; // Return the summary from the backend
        } else {
            throw new Error('Failed to summarize conversation.');
        }
    } catch (error) {
        console.error('Error summarizing conversation:', error.message);
        throw error;
    }
}

async function fetchSourceList() {
    try {
        const response = await axios.get(`${serverurl}/listDataStores`);
        console.log('Received response from the API:', response.data);

        // Combine data sources and agents into a single array
        const allSources = [
            { id: 'google-search', name: 'Google Search', type: 'other', icon: <GoogleIcon fontSize='small' /> }, // Google Search
            ...response.data.stores.map(store => ({
                ...store,
                type: 'store',
                isMultimodal: false,
                icon: <LibraryBooks fontSize='small' />

            })),
            ...(await fetchAgentList()).map(agent => ({ // Fetch agents and add to the array
                ...agent,
                type: 'agent',
                isMultimodal: false, // Adjust if your agents support images,
                icon: <SmartToy fontSize='small' />
            }))
        ];


        return allSources;
    } catch (error) {
        console.error('Error fetching sources:', error);
        return [];
    }
}
async function fetchAgentList() {
    try {

        const response = await axios.get(`${serverurl}/listAgents`);
        return response.data || []; // Return universes or empty array on error
    } catch (error) {
        console.error('Error fetching Agent list:', error);
        return []; // Return empty array on error
    }
}

async function fetchSignedUrlForFile(file) {
    try {

        const response = await axios.get(`${serverurl}/get_file`, { params: { file_name: file.name } });
        return response.data || [];
    } catch (error) {
        console.error('Error fetching signed url:', error);
        return []; // Return empty array on error
    }
}

export { sendChatMessage, summarizeConversation, fetchSourceList, fetchAgentList, suggestQuestions, fetchSignedUrlForFile }; // Export the new function
