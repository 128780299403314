/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// FileUploadModal.jsx
import React, { useState, useCallback } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Box, Button, Typography, List, ListItem, ListItemIcon, LinearProgress,
    TextField, useTheme // ...and other Material UI components as needed
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import {
    InsertDriveFile,
    Image,
    AudioFile,
    VideoFile,
    Description, CloudUpload
} from '@mui/icons-material';

function FileUploadModal({ open, onClose, onUpload }) {
    const [files, setFiles] = useState([]);
    const [pastedContent, setPastedContent] = useState('');
    const [uploadProgress, setUploadProgress] = useState({}); // Track upload progress per file
    const theme = useTheme();

    const onDrop = useCallback(acceptedFiles => {
        setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleFileSelect = (event) => {
        setFiles(prevFiles => [...prevFiles, ...event.target.files]);
    };


    const handleRemoveFile = (index) => {
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        setUploadProgress(prevProgress => {
            const newProgress = { ...prevProgress };
            delete newProgress[index];
            return newProgress;
        });
    };

    const handleUpload = () => {
        if (files.length > 0 || pastedContent !== '') {
            onUpload(files); // Pass files to ChatInterface for rendering
        }
        onClose(); // Close the modal after successful upload (or even if no files are uploaded, since you want it to close regardless)
    };


    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Upload Files/Folders</DialogTitle>
            <DialogContent>
                <Box
                    {...getRootProps()}
                    sx={{
                        border: '2px dashed gray',
                        p: 4,
                        borderRadius: 2,
                        textAlign: 'center',
                        bgcolor: theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.grey.light,
                    }}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <Typography variant="body1">Drop the files here ...</Typography>
                    ) : (
                        <>
                            <CloudUpload sx={{ fontSize: 60, mb: 2 }} />
                            <Typography variant="body1">
                                Drag and drop files or folders here, or
                            </Typography>
                            <Button variant="contained" component="label">
                                Browse Files
                                <input type="file" hidden multiple onChange={handleFileSelect} />
                            </Button>
                        </>
                    )}
                </Box>



                <List sx={{ mt: 2 }}>
                    {files.map((file, index) => (
                        <ListItem key={index} secondaryAction={<Button onClick={() => handleRemoveFile(index)}>Remove</Button>}>
                            <ListItemIcon>
                                {file.type.startsWith('image/') ? (
                                    <Image />
                                ) : file.type.startsWith('audio/') ? (
                                    <AudioFile />
                                ) : file.type.startsWith('video/') ? (
                                    <VideoFile />
                                ) : (
                                    <InsertDriveFile />
                                )}
                            </ListItemIcon>
                            <Typography variant="body1">
                                {file.name} ({(file.size / 1024).toFixed(2)} KB)
                                {uploadProgress[index] && <LinearProgress variant="determinate" value={uploadProgress[index]} />}
                            </Typography>
                        </ListItem>
                    ))}

                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleUpload} variant="contained" color="primary" disabled={files.length === 0 && !pastedContent}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FileUploadModal;