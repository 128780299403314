/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

let isDataLoaded = false;
const BASE_URL = 'http://localhost:5000';

const modelOptions = {
    'gemini-1.0-pro-002': { label: 'Gemini 1.0 Pro', type: 'model', isMultimodal: false },
    'gemini-1.5-pro-002': { label: 'Gemini 1.5 Pro', type: 'model', isMultimodal: true },
    'gemini-1.5-flash-002': { label: 'Gemini 1.5 Flash', type: 'model', isMultimodal: true },
};

export default modelOptions; 
