/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, ListItem, ListItemAvatar, Avatar, useTheme, Grid, Paper, Link, Tooltip, IconButton } from '@mui/material';
import { Marked, Renderer } from 'marked';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // Import ExpandLessIcon
import EditIcon from '@mui/icons-material/Edit';

import FileDisplay from './FileDisplay';
import { markedHighlight } from "marked-highlight";
import hljs from 'highlight.js'; // Import highlight.js

import { FileIcon, defaultStyles } from 'react-file-icon';


function Message({ text, files, sender, avatarSrc, citations, onEdit, isLastMessage, id }) {
    const theme = useTheme();
    const elementId = `message-text-${id}`;

    const marked = new Marked(
        markedHighlight({
            langPrefix: 'hljs language-',
            highlight(code, lang, info) {
                const language = hljs.getLanguage(lang) ? lang : 'plaintext';
                return hljs.highlight(code, { language }).value;
            }
        }),
        {
            renderer: new Renderer({
                code: (code, lang) => {
                    const language = hljs.getLanguage(lang) ? lang : 'plaintext';
                    const highlighted = hljs.highlight(code, { language }).value;
                    return `<pre class="hljs language-${language}"><code class="hljs">${highlighted}</code></pre>`;
                }
            })
        }
    );

    const chatBackgroundColor = sender === 'user' ? theme.palette.chat.user : theme.palette.chat.bot;

    let themeLinkRef = useRef(null);

    const [showFullText, setShowFullText] = useState(false);
    const [editText, setEditText] = useState(text);

    useEffect(() => {
        // Load the default CSS file first

        themeLinkRef.current = document.createElement('link');
        themeLinkRef.current.id = 'theme-link'; // Set an ID for the link
        themeLinkRef.current.rel = 'stylesheet';
        themeLinkRef.current.href = 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/default.min.css';
        document.head.appendChild(themeLinkRef.current);
    }, []);



    // Dynamically apply the correct highlight.js theme based on the theme
    useEffect(() => {
        if (theme.palette.mode === 'dark') {
            themeLinkRef.current.href = 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/atom-one-dark.min.css';
        } else {
            themeLinkRef.current.href = 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/default.min.css';
        }

    }, [theme.palette.mode]);

    const handleEditClick = () => {
        setEditText(text);
        onEdit(editText);
    };

    const handleEditChange = (event) => {
        setEditText(event.target.value);
    };

    const handleEditSubmit = () => {
        onEdit(editText);
    };

    const handleExpandClick = () => {
        setShowFullText(!showFullText);
    };

    const hasOverflow = () => {
        const messageElement = document.getElementById(elementId);
        if (messageElement) {
            return messageElement.scrollHeight > messageElement.clientHeight;
        }
        return false;
    };

    return (
        <ListItem disableGutters alignItems="flex-start" sx={{
            mb: 2, width: '100%', display: 'flex'
        }}>  {/* Add margin-bottom */}
            <ListItemAvatar>
                <Avatar src={avatarSrc} sx={{ width: 40, height: 40 }} /> {/* Larger avatar */}
            </ListItemAvatar>
            <Box sx={{
                bgcolor: chatBackgroundColor,
                p: 2, // Increased padding
                borderRadius: 3, // More rounded corners
                boxShadow: theme.shadows[1], // Add a subtle shadow
                color: theme.palette.getContrastText(chatBackgroundColor),
                maxWidth: '70vw',
            }}>
                <Grid container spacing={1} sx={{ width: '100%' }}>
                    <Grid item xs={12} md={12}>
                        {sender != 'user' ? (
                            <div dangerouslySetInnerHTML={{ __html: marked.parse(text) }} />
                        ) :
                            (
                                <Box sx={{
                                    overflow: showFullText ? 'visible' : 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: showFullText ? 'unset' : 5,
                                    WebkitBoxOrient: 'vertical'
                                }} id={elementId}>

                                    {editText}

                                </Box>
                            )}
                    </Grid>
                    {sender === 'user' && (hasOverflow() || showFullText) && ( // Show button if there is overflow
                        <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <IconButton onClick={handleExpandClick} aria-label="expand">
                                {showFullText ? <ExpandLessIcon /> : <ExpandMoreIcon />} {/* Use ExpandLessIcon when expanded */}
                            </IconButton>
                        </Grid>
                    )}
                    <Grid item xs={12} md={12} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {citations && citations.map((citation, index) => (
                            <Tooltip key={"citation-" + index} title={citation.extractive_answer} placement="top" arrow>
                                <Paper key={citation.url} elevation={1} sx={{ m: 1, p: 1, maxWidth: '300px', minWidth: '150px' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="subtitle2" sx={{ mr: 1 }}>[{index + 1}]</Typography>
                                        <Box sx={{ width: 20 }}>
                                            <FileIcon extension={citation.storage_path.split('.').pop()} {...defaultStyles[citation.storage_path.split('.').pop()]} />
                                        </Box>
                                        <Link href={citation.url} underline="hover" sx={{ display: 'block', ml: 1, color: 'inherit' }}>
                                            <Typography variant="subtitle2">{citation.storage_path.split('/').pop()}</Typography>
                                        </Link>
                                    </Box>

                                </Paper>
                            </Tooltip>

                        ))}
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
                        {files && files.map((file) => (
                            <FileDisplay key={file.url} file={file} />
                        ))}
                    </Grid>
                    {/* {sender === 'user' && isLastMessage && (
                        <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <IconButton onClick={handleEditClick} aria-label="edit">
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    )} */}
                </Grid>
            </Box>
        </ListItem>
    );
}

export default Message;