/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// DbContext.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { firebaseConfig } from '../config/firebaseConfig';

// Initialize Firebase (move this here from firebase.js)
initializeApp(firebaseConfig);

// Create the database context
const DbContext = createContext({
    db: null,
});

// Database provider component
export const DbProvider = ({ children }) => {
    // Initialize Firestore (use 'useState' to hold the db instance)
    const [db, setDb] = useState(null);

    useEffect(() => {
        // Get a reference to the Firestore database
        const firestore = getFirestore();
        setDb(firestore);
    }, []); // Empty dependency array ensures this runs only once

    return (
        <DbContext.Provider value={{ db }}>
            {children}
        </DbContext.Provider>
    );
};

// Custom useDb hook
export const useDb = () => {
    const context = useContext(DbContext);
    if (context === undefined) {
        throw new Error('useDb must be used within a DbProvider');
    }
    return context;
};
