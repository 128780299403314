/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react'
import ReactDOM from 'react-dom/client';
import './index.css';

import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import theme from './theme';
import { CustomThemeProvider } from './context/ThemeContext';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { DbProvider } from './context/DbContext';
import { ChatProvider } from './context/ChatContext';


// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <DbProvider>
          <CustomThemeProvider>
            <MuiThemeProvider theme={theme}>
              <ChatProvider>
                <AuthContext.Consumer>
                  {({ user, isLoading }) => <AppRoutes user={user} isLoading={isLoading} />}
                </AuthContext.Consumer>
              </ChatProvider>
            </MuiThemeProvider>
          </CustomThemeProvider>
        </DbProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
