/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { Suspense, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./components/HomePage";
import ChatApp from "./ChatApp"; // Renamed the main component to ChatApp
import { useAuth } from "./context/AuthContext";
import { ThemeContext } from "./context/ThemeContext";



function AppRoutes() {

    const { user, isLoading: authLoading } = useAuth();
    const { isLoading: themeLoading } = useContext(ThemeContext);

    if (authLoading || themeLoading) {
        return <Suspense fallback={<div>Loading...</div>} />; // Or any other loading indicator you prefer
    }

    return (
        <Routes>
            <Route
                path="/"
                element={user ? <ChatApp /> : <Navigate replace to="/welcome" />} // Redirection
            />
            <Route path="/welcome" element={!user ? <HomePage /> : <Navigate replace to="/" />} />
            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
    );
}

export default AppRoutes;
