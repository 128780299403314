/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createTheme } from '@mui/material/styles';
import { light } from '@mui/material/styles/createPalette';

const commonStyles = {
    typography: {
        fontFamily: 'Roboto, sans-serif',  // Choose a clear, readable font
    },
};


export const lightTheme = createTheme({
    ...commonStyles,
    palette: {
        mode: 'light',
        primary: {
            main: '#4285F4',
            dark: '#3367d6'
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            default: '#ffffff', // Light background for better contrast in light mode
            paper: '#fff',
            light: '#f5f5f5',
            gradient: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)', // Dark text for light mode
            secondary: 'rgba(0, 0, 0, 0.54)', // Medium text for light mode
            reversed: 'rgba(0, 0, 0, 0.87)'
        },
        grey: {
            light: '#f5f5f5',
            medium: '#e0e0e0',
            primary: 'grey.200',
            secondary: 'grey.800',
        },
        chat: {
            user: '#d0e8f5',
            bot: '#e8eaf6',
        },
        action: {
            selected: '#E3F2FD', // Light blue, derived from primary
            hover: '#BBDEFB',
        },
        suggestionChipGradient: {
            default: 'linear-gradient(135deg, #DCE2FF 0%, #ECDCFB 100%)',
        }
    }
    ,
    components: {
        MuiCard: { // Style the cards
            styleOverrides: {
                root: {
                    borderRadius: 8, // Slightly rounded corners
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
                },
            },
        }
    }
    // ... typography and other styles
});

export const darkTheme = createTheme({
    ...commonStyles,
    palette: {
        mode: 'dark',
        primary: {
            main: '#303030', // Lighter blue for accents
            dark: '#42a5f5', // Darker blue for hover
        },
        secondary: {
            main: '#f48fb1', // Pink accent
        },
        background: {
            default: 'black', // Dark gray background
            light: 'black',
            paper: '#424242',
            gradient: 'linear-gradient(135deg, #212121 0%, #424242 100%)'
        },
        text: {
            primary: '#ffffff',
            secondary: '#9e9e9e',
            reversed: 'rgba(0, 0, 0, 0.87)'
        },
        grey: {
            light: '#616161',
            medium: '#424242',
            primary: 'grey.700',
            secondary: 'grey.100',
        },
        chat: {
            user: '#263238', // Darker blue
            bot: '#37474f',  // Darker gray
        },
        action: {
            selected: '#42a5f5', // Darker gray for selected in dark mode
            hover: '#616161', // Slightly lighter gray for hover in dark mode
        },

        suggestionChipGradient: {
            default: 'linear-gradient(135deg, #2E64DE 0%, #BE6EAE 100%)',
        },
        components: {
            MuiCard: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#424242', // Darker card background for dark mode
                        color: '#ffffff', // White text on cards for dark mode
                        // ...
                    },
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#303030', // Darker background for AppBar in dark mode
                    },
                },
            },
        }
    }
});

export default lightTheme;