/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useContext } from 'react';
import { Box, Typography, Button, Container, Grid, Paper, Stack, useTheme } from '@mui/material';
import { AuthContext } from '../context/AuthContext';


function HomePage() {
    const theme = useTheme();
    const { signIn } = useContext(AuthContext);


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                bgcolor: 'background.default',
                textAlign: 'center',
                backgroundImage: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)', // Subtle gradient (optional)
            }}
        >
            <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', alignItems: 'center', bgcolor: 'transparent' }}>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                            <img src='/home-image.jpg' alt="Home Page" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </Paper>

                    </Grid>
                    <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Stack spacing={4} alignItems="center">
                            <img src='/gemini-logo.svg' alt="Gemini Logo" style={{ width: 300 }} />

                            {/* Visual Hierarchy Improvements */}
                            <Box>
                                <Typography variant="h3" component="h1" fontWeight="bold" sx={{ mb: 1 }}>
                                    Unleash Your
                                </Typography>
                                <Typography variant="h3" component="h1" fontWeight="bold" color="#4285F4">
                                    Productivity
                                </Typography>
                            </Box>

                            <Typography variant="h6" component="h2" sx={{ color: 'grey.700' }}>
                                Meet Gemini, Your AI Assistant
                            </Typography>

                            {/* Stronger Value Proposition */}
                            <Typography variant="subtitle1" color="text.secondary" sx={{ maxWidth: 600, lineHeight: 1.5 }}>
                                Get instant answers, automate tasks, and collaborate more effectively.
                                Write faster, brainstorm ideas, learn new skills, and create stunning visuals
                                with ease, all powered by Google's cutting-edge AI.
                            </Typography>

                            {/* Compelling Call to Action */}
                            <Button
                                variant="contained"
                                onClick={signIn}
                                sx={{
                                    borderRadius: 25,
                                    px: 4,
                                    py: 1.5,
                                    fontSize: '1rem',
                                    backgroundColor: theme.palette.primary.main, // Use theme variable
                                    '&:hover': { backgroundColor: theme.palette.primary.dark }
                                }}
                            >
                                Login
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

            </Container>
        </Box>
    );
}

export default HomePage;