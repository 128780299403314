/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Modal, Paper, Typography, Box, Button, keyframes, Avatar } from "@mui/material";
import Confetti from "react-confetti";
import { CheckCircleOutline } from "@mui/icons-material";
import { useTheme } from '@mui/material/styles'; // Add this import


const confettiBurst = keyframes`
  0% { opacity: 1; transform: scale(1) translateY(0); }
  100% { opacity: 0; transform: scale(2) translateY(-50px); }
`;

const IntroEndModal = ({ open, onClose, onStartNewChat }) => {
    const theme = useTheme();

    return (
        <Modal open={open} onClose={onClose}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    bgcolor: "background.paper", // White background
                    minWidth: 300,
                    overflow: "hidden",
                }}
            >
                <Avatar src='/bot_icon.png' sx={{ width: 80, height: 80 }} />

                <Typography variant="h6" component="h2" align="center" sx={{ mt: 2 }}>
                    Thank you for providing your information!
                </Typography>
                <Typography variant="subtitle1" component="h3" align="center" sx={{ mt: 2 }}>
                    This will help personalizing your experience a lot!
                </Typography>

                {/* Confetti with burst animation */}
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {open && (
                        <Confetti
                            recycle={true} // Only play once
                            numberOfPieces={150}
                            gravity={0.1} // Adjust as needed
                            tweenDuration={2000} // Duration of the confetti animation
                            initialVelocityX={{ min: -1, max: 4 }}
                            initialVelocityY={{ min: -30, max: 0 }}
                            onConfettiComplete={() => { }} // Do nothing on complete (no recycle)
                            style={{
                                opacity: 1, // Initial opacity
                                animation: `${confettiBurst} ${2}s ease-out forwards`, // Burst animation
                            }}
                        />
                    )}
                </Box>

                <Button
                    variant="contained"
                    onClick={onStartNewChat}
                    sx={{ mt: 3, bgcolor: theme.palette.primary.main }} // Use primary color for button
                >
                    Let's Chat!
                </Button>
            </Paper>
        </Modal>
    );
};

export default IntroEndModal;
