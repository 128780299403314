/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchSourceList } from '../backend/vertexAI';



export const ChatContext = createContext({
    chatSessionId: null,
    showWelcome: true,
    sourceList: [],
    setSourceList: () => { },
    selectedSource: '',
    setSelectedSource: () => { },
    setChatSessionId: () => { },
    setShowWelcome: () => { },
    isIntroChat: false,
    setIsIntroChat: () => { },
    hasUserContext: false,
    userContext: "",
    setUserContext: () => { },
});

export const ChatProvider = ({ children }) => {
    const [chatSessionId, setChatSessionId] = useState(null);
    const [showWelcome, setShowWelcome] = useState(true);
    const [sourceList, setSourceList] = useState([]);
    const [selectedSource, setSelectedSource] = useState('');
    const [isIntroChat, setIsIntroChat] = useState(false);
    const [hasUserContext, setHasUserContext] = useState(false);
    const [userContext, setUserContext] = useState({});


    useEffect(() => {
        console.log('userContext', userContext);
        setHasUserContext(userContext && Object.keys(userContext).length > 0);
    }, [userContext])

    // Fetch data sources and agents on component mount
    useEffect(() => {
        // Fetch the list of universes from your backend on component mount
        const getSourceList = async () => {
            const source = await fetchSourceList();
            setSourceList(source); // Set the combined source list
        };

        getSourceList();
    }, []);

    return (
        <ChatContext.Provider value={{
            chatSessionId, showWelcome,
            sourceList,
            selectedSource,
            setSelectedSource,
            setSourceList, setChatSessionId, setShowWelcome,
            isIntroChat, setIsIntroChat,
            hasUserContext,
            userContext,
            setUserContext
        }}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChat = () => {
    const context = useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChat must be used within a ChatProvider');
    }
    return context;
};
