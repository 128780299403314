import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import ReactPlayer from 'react-player';
import { fetchSignedUrlForFile } from '../backend/vertexAI';
import { FileIcon } from 'react-file-icon';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import CircularProgress from '@mui/material/CircularProgress'; // Add loading spinner
import FileCard from './FileCard';

// Configure worker script path for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function FileDisplay({ file }) {

    const [isLoading, setIsLoading] = useState(false);
    const [fileLoaded, setFileLoaded] = useState(false);
    // State to track if the file is local
    const [isLocal, setIsLocal] = useState(false);
    // State to store the file type
    const [fileType, setFileType] = useState(null);
    // State to store the file URL
    const [signedUrl, setSignedUrl] = useState(null);


    console.log(file)

    // Helper function to fetch the signed URL and determine file type
    const fetchAndPrepareFile = async (file) => {
        try {
            setIsLoading(true); // Set loading state to true
            if (file.url) { // Check if the file has a URL (uploaded)
                const response = await fetchSignedUrlForFile(file);
                console.log('Signed URL:', response.url);
                setSignedUrl(response.url);
            }
        } catch (error) {
            console.error('Error fetching signed URL:', error);
            return null;
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    // Fetch and prepare the file data when the file prop changes
    useEffect(() => {
        if (file.url) { // Only fetch signed URL if file has a URL (uploaded)
            fetchAndPrepareFile(file)
                .then(
                    setFileLoaded(true)
                );
        } else { // File is local
            setIsLocal(true); // Set isLocal to true for local files
            setFileLoaded(true); // Set fileLoaded to true for local files
        }
    }, [file]);

    // Function to determine file type based on MIME type
    const determineFileType = (fileType) => {

        const isImage = fileType.startsWith('image/');
        const isPdf = fileType === 'application/pdf';
        const isVideo = ['video/mp4', 'video/webm', 'video/avi'].includes(fileType);
        const isAudio = ['audio/mpeg', 'audio/wav', 'audio/ogg'].includes(fileType);
        return { isImage, isPdf, isVideo, isAudio };
    };

    const { isImage, isPdf, isVideo, isAudio } = determineFileType(file.type); // Get file type flags

    return (
        <Grid container spacing={1} sx={{ mt: 1 }}>

            <Grid item>
                {
                    isLocal ? (
                        <Box>
                            <FileCard file={file} />
                        </Box>)
                        :
                        (isLoading ? (
                            <CircularProgress />
                        ) : (
                            isImage ? (
                                <a href={signedUrl} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={signedUrl}
                                        alt={file.name}
                                        style={{ maxWidth: 200, maxHeight: 150 }}
                                    />
                                </a>
                            ) : isPdf ? (
                                <a href={signedUrl} target="_blank" rel="noopener noreferrer">
                                    <Document file={signedUrl}>
                                        <Page pageNumber={1} height={150} />
                                    </Document>
                                </a>
                            ) : isVideo ? (
                                <ReactPlayer url={signedUrl} controls width="100%" height={150} />
                            ) : (
                                <FileIcon extension={fileType.split('/').pop()} size={60} />
                            )
                        )
                        )

                }

            </Grid>
        </Grid>
    );
}

export default FileDisplay;