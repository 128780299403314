/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// WelcomeCard.jsx
import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";

function WelcomeCard({ image, icon: IconComponent, title, content }) {
    return (
        <Card sx={{ height: "100%", maxWidth: 345, transition: "transform 0.2s", "&:hover": { transform: "translateY(-2px)", boxShadow: 3 } }}>
            <CardMedia
                component="img"
                height="140"
                image={image}
                alt={title} // Use the title as the image alt text for better accessibility
            />
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div">
                    <IconComponent /> {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {content}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default WelcomeCard;
