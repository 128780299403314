/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState, useRef, useEffect, useContext, forwardRef } from 'react';
import {
    useTheme,
    Box, TextField, Button, Stack, Grid, Avatar, Divider, Skeleton, ListItem,
    ListItemAvatar, Toolbar, InputAdornment, styled, Modal, Paper,
    Card, CardContent, Typography, CardMedia, Chip, Icon,
} from '@mui/material';
import Message from './Message';
import WelcomeMessage from './WelcomeMessage';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';

import { useAuth } from '../context/AuthContext';
import { suggestQuestions } from '../backend/vertexAI';

import { ChatContext } from '../context/ChatContext';
import FileUploadModal from './FileUpload';
import FileCard from './FileCard';

import { ReactComponent as Logo } from "../static/images/smart_assistant.svg";
// import SvgIcon from "@material-ui/core/SvgIcon";


// Styled components for the input area
const InputContainer = styled(Box)(({ theme, $isdraweropen }) => ({
    position: 'fixed', // Keep the input fixed at the bottom
    bottom: 0,
    left: $isdraweropen ? 'calc(5% + 240px)' : '20%', // Dynamically adjust left based on drawer state
    width: '70%', // Adjust width accordingly
    padding: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        borderRadius: '20px', // Rounded corners
        backgroundColor: theme.palette.grey.light,
        paddingRight: theme.spacing(4), // Extra padding for the icons
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    background: theme.palette.suggestionChipGradient.default, // Gradient from theme
    mr: 1,
    cursor: 'pointer',
    color: theme.palette.grey.secondary,
    transition: 'transform 0.2s, boxShadow 0.2s',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: 2,
        // Adjust hover colors if needed
    },
}));



function ChatInterface({ messages, onSendMessage, userInput, onUserInputChange, smartQuestion, setSmartQuestion, selectedSession,
    isWaitingForResponse, modelOptions,
    selectedModelId, handleFileUpload, setSelectedFiles,
    selectedFiles, isDrawerOpen }) {
    const [modalOpen, setModalOpen] = useState(false);
    const selectedModel = modelOptions[selectedModelId];
    const [smartQuestionList, setSmartQuestionList] = useState([]);


    const theme = useTheme();
    const { chatSessionId, showWelcome, isIntroChat } = useContext(ChatContext);

    const messagesEndRef = useRef(null);

    const auth = useAuth()

    const [chatContainerBottomPadding, setChatContainerBottomPadding] = useState('100px'); // Initialize with default value
    const inputContainerRef = useRef();
    const chipsContainerRef = useRef(); // Ref for the chip container


    useEffect(() => {
        const calculateBottomPadding = () => {
            if (inputContainerRef.current) {
                const inputContainerHeight = inputContainerRef.current.offsetHeight;
                // Check for chip container height only if the container exists
                const chipContainerHeight = chipsContainerRef.current ? chipsContainerRef.current.offsetHeight : 0;

                // Set the bottom padding to inputContainerHeight plus an additional 16px (or theme.spacing(2)) for space between chips and textfield
                setChatContainerBottomPadding(`${inputContainerHeight + chipContainerHeight + 16}px`);
            }
        };

        calculateBottomPadding(); // Initial calculation

        // Add a resize event listener
        const resizeObserver = new ResizeObserver(calculateBottomPadding);

        // Observe both the input container and the chips container
        if (inputContainerRef.current) {
            resizeObserver.observe(inputContainerRef.current);
        }
        if (chipsContainerRef.current) {
            resizeObserver.observe(chipsContainerRef.current);
        }

        // Clean up the event listener
        return () => {
            if (inputContainerRef.current) {
                resizeObserver.unobserve(inputContainerRef.current);
            }
            if (chipsContainerRef.current) {
                resizeObserver.unobserve(chipsContainerRef.current);
            }
        };
    }, [smartQuestionList, messages, selectedFiles]);

    useEffect(() => {
        const fetchSuggestions = async () => {
            const newSuggestedQuestions = await suggestQuestions(
                messages[chatSessionId] || [] // Pass the conversation history
            );
            setSmartQuestionList(newSuggestedQuestions);

        };

        if (!isIntroChat && !showWelcome && messages[chatSessionId]?.length > 0) { // Only fetch when there are messages
            fetchSuggestions();
        } else {
            setSmartQuestionList([]);
        }
    }, [messages, chatSessionId, showWelcome]);


    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleUpload = (files) => {
        setSelectedFiles(files)
        handleCloseModal();
        console.log('Files uploaded:', files);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) { // Send only if Enter is pressed without Shift
            event.preventDefault(); // Prevent default newline behavior
            setSmartQuestionList([]);
            onSendMessage();
        }
    };

    // Add this to your existing useEffect in ChatInterface.jsx
    useEffect(() => {
        if (selectedSession && messages[selectedSession] && messages[selectedSession].length > 0) { // Check if session and messages exist
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll
            }
        }
    }, [selectedSession, messages]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, isWaitingForResponse]);

    const handleRemoveFile = (index) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const handleChipClick = (question) => {
        setSmartQuestionList([]);
        setSmartQuestion(question); // Update the input field with the clicked question
    };


    return (

        <Box sx={{
            display: 'flex', flexDirection: 'column', minHeight: '100vh',
            background: theme.palette.background.default,
        }}>
            <Toolbar></Toolbar>
            <Box sx={{
                flexGrow: 1, overflowY: 'auto', p: 2,
                paddingBottom: chatContainerBottomPadding, bgcolor: theme.palette.background.default
            }}>
                {showWelcome ? (

                    <WelcomeMessage />

                ) : (
                    messages[selectedSession]?.map((msg, index) => (

                        <Message
                            key={index}
                            id=
                            {index}
                            text={msg.text}
                            files={msg.files}
                            sender={msg.sender}
                            citations={msg.citations}
                            isLastMessage={index === messages[selectedSession]?.length - 2}
                            onEdit={(newText) => console.log('edit')}
                            avatarSrc={msg.sender === 'user' ? auth.avatarUrl : '/bot_icon.png'}
                        />




                    ))

                )}

                {isWaitingForResponse && (
                    <ListItem disableGutters alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar src="/static/images/bot-avatar.png" />
                        </ListItemAvatar>
                        <Box sx={{ width: '100%' }}>
                            <Skeleton width="60%" height={40} />
                            <Skeleton animation="wave" width="60%" height={40} />
                            <Skeleton animation={false} width="60%" height={40} />
                        </Box>
                    </ListItem>
                )}


            </Box>

            <div style={{ flexGrow: 1 }} ref={messagesEndRef} />
            <InputContainer $isdraweropen={isDrawerOpen} ref={inputContainerRef}>
                <Box display="flex" alignItems="center" flexWrap="wrap"> {/* Allow wrapping for multiple cards */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }} >
                        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap"
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                gap: 1,
                                '& > .MuiChip-root': { m: 0.5 } // Added this for better spacing
                            }}>
                            {smartQuestionList.map((question, qIndex) => (
                                <StyledChip
                                    key={qIndex}
                                    label={<Typography variant="subtitle1">{question}</Typography>}
                                    variant="outlined"
                                    onClick={() => handleChipClick(question)}


                                    icon={<Box sx={{
                                        pl: 1, pr: 0.5, display: 'flex', alignItems: 'center',
                                        '& svg': { transform: 'scale(0.8)' } // Scale the SVG down to 80%
                                    }}>
                                        <Logo fontSize="small" fill={theme.palette.text.primary} />
                                    </Box>}
                                />
                            ))}
                        </Stack>
                    </Box>
                    <StyledTextField
                        fullWidth
                        variant="outlined"
                        multiline  // Allow multiple lines in the input
                        maxRows={4}
                        value={userInput}
                        onChange={onUserInputChange}
                        placeholder={selectedModel?.isMultimodal ? "Type your message or upload an image..." : "Type your message..."}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {/* File Upload Button (if applicable) */}
                                    {selectedModel?.isMultimodal && (
                                        <IconButton color="primary" aria-label="upload file" component="label" onClick={handleOpenModal}>

                                            <AttachFileIcon />
                                        </IconButton>
                                    )}

                                    {/* Send Button */}
                                    <IconButton color="primary" aria-label="send" onClick={onSendMessage}>
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    > </StyledTextField>
                    {Array.isArray(selectedFiles) && selectedFiles.length > 0 && selectedFiles.map((file, index) => (
                        <FileCard key={index} index={index} file={file} onRemove={handleRemoveFile} />
                    ))}
                </Box>
                {/* File Upload Modal */}
                <Modal open={modalOpen} onClose={handleCloseModal}>
                    <Paper sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 4 }}>
                        <FileUploadModal open={modalOpen} onClose={handleCloseModal} onUpload={handleUpload} />
                    </Paper>
                </Modal>
            </InputContainer>

        </Box>
    );
}

export default ChatInterface;