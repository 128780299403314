/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { useContext } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListSubheader,
    Button,
    FormGroup,
    FormControlLabel,
    Switch, useTheme
} from '@mui/material';

import { ThemeContext } from '../context/ThemeContext';
import modelOptions from '../config/modelOption';
import { useAuth } from '../context/AuthContext';
import { useDb } from '../context/DbContext';

function SetupContent({ selectedOptionId, setSelectedOptionId, handleCloseSetupModal }) {
    const { user } = useAuth();
    const { db } = useDb();

    const { isDarkMode, toggleColorMode } = useContext(ThemeContext);
    const handleModelChange = (event) => {
        setSelectedOptionId(event.target.value);
    };

    const saveAndClose = async () => {
        // Save options to database
        try {
            const userRef = doc(db, 'users', user.uid); // Get a reference to the user document
            await setDoc(userRef, {
                selectedModelId: selectedOptionId,
                isDarkMode: isDarkMode || false
            }, { merge: true }); // Update the user document, merging new data

        } catch (error) {
            console.error('Error saving options to database:', error);
        }
        handleCloseSetupModal();
    };



    return (
        <Dialog open={true} onClose={() => { }} maxWidth="md">
            <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Chat Settings</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Customize your chat experience by choosing a model/agent and a knowledge base source.
                </DialogContentText>

                {/* Model/Agent Selection Dropdown */}
                <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel id="option-select-label">Model/Agent</InputLabel>
                    <Select
                        labelId="option-select-label"
                        id="option-select"
                        value={selectedOptionId}
                        onChange={handleModelChange}
                        label="Model/Agent"
                    >
                        <ListSubheader>Models</ListSubheader>
                        {Object.keys(modelOptions)
                            .filter(optionId => modelOptions[optionId].type === 'model')
                            .map(optionId => (
                                <MenuItem key={optionId} value={optionId}>
                                    {modelOptions[optionId].label}
                                </MenuItem>
                            ))}

                    </Select>
                </FormControl>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={isDarkMode} onChange={toggleColorMode} />}
                        label="Dark Mode"
                    />
                </FormGroup>




                {/* Add other setup controls here (e.g., text fields, sliders) */}
            </DialogContent>
            <DialogActions>
                <Button onClick={saveAndClose} variant="contained" color="primary">
                    Save & Close
                </Button></DialogActions>
        </Dialog>
    );
}

export default SetupContent;
