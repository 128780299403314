/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useContext, useState } from 'react';
import {
    ListItemButton, Avatar, Typography, Box, ListItem, List, Divider, Button, IconButton,
    Tooltip, Menu, MenuItem, Modal, Grid, Card, CardHeader, CardContent, TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Chat from '@mui/icons-material/Chat';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info'; // Import InfoIcon
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // Import MoreVertIcon
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon

import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Import CalendarTodayIcon
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'; // Import ChatBubbleIcon
import TokenIcon from '@mui/icons-material/Token'; // Import TokenIcon

import {
    doc, deleteDoc, getDoc
} from "firebase/firestore";

import { useAuth } from '../context/AuthContext';
import { useDb } from '../context/DbContext';

import { ChatContext } from '../context/ChatContext';



function ConversationsList({ conversationsWithMessages }) {
    const theme = useTheme()
    const { chatSessionId, setChatSessionId, setShowWelcome } = useContext(ChatContext);
    const [showMore, setShowMore] = useState(false); // State to toggle 'Show More'
    const [anchorEl, setAnchorEl] = useState(null); // State to control the context menu
    const [selectedSessionId, setSelectedSessionId] = useState(null); // State to store the selected conversation's sessionId
    const [openModal, setOpenModal] = useState(false); // State to control the modal
    const [selectedConversationStats, setSelectedConversationStats] = useState({ 'title': '', 'creationDate': '', 'chatCount': '', 'tokenCount': '' }); // State to store the selected conversation's stats


    const auth = useAuth()
    const { user } = auth;
    const dbContext = useDb();
    const db = dbContext.db;

    const open = Boolean(anchorEl);

    // Function to handle click on the MoreVertIcon
    const handleClick = (event) => {
        // get the sessionId from the event
        const sessionId = event.currentTarget.getAttribute('data-sessionid');
        setSelectedSessionId(sessionId); // Set the selected conversation's sessionId
        setAnchorEl(event.currentTarget); // Set anchorEl to the clicked IconButton
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    // function to delete a conversation from firebase
    const deleteConversation = async (sessionId) => {
        try {
            const path = `users/${user.uid}/chats`;
            await deleteDoc(doc(db, path, sessionId))
            console.log('Conversation deleted successfully');
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
    };

    // Function to retrive converstation stats from firebase
    const getConversationStats = async (sessionId) => {
        try {
            const path = `users/${user.uid}/chats/${sessionId}`;
            console.log(path);
            const docRef = doc(db, path);
            const docSnap = await getDoc(docRef);
            let statObj = {};
            if (docSnap.exists()) {
                const data = docSnap.data();
                console.log(data);
                // add creation date, chat counts and token # to the stat objecty
                statObj = {
                    title: data.title,
                    // convert the createdAt date from firestore to a string
                    creationDate: data.createdAt.toDate().toLocaleString(),
                    chatCount: data.conversation.length,
                    //get the sum of all tokens in the conversation
                    tokenCount: data.conversation.reduce((acc, curr) => acc + (!isNaN(curr.tokens) ? curr.tokens : 0), 0)
                };
                setSelectedConversationStats(statObj)
            } else {
                console.log('No such document!');
            }
        } catch (error) {
            console.log('Error getting document:', error);
        }
    };

    // Function to handle delete action from the menu
    const handleDelete = (sessionId) => {
        deleteConversation(sessionId);
        handleClose(); // Close the menu after deleting
    };

    // Function to handle the "More Info" button click
    const handleMoreInfo = (sessionId) => {
        setSelectedSessionId(sessionId);
        getConversationStats(sessionId);

        setOpenModal(true);
    };

    // Function to close the modal
    const handleModalClose = () => {
        setOpenModal(false);
    };

    // Get the last 5 conversations (or all if there are less than 5)
    const recentConversations = conversationsWithMessages.slice(0, showMore ? undefined : 5);

    return (
        conversationsWithMessages.length > 0 && (
            <List>
                <ListItem disablePadding>
                    <ListItemButton>
                        <Typography variant="h6">Recent</Typography>
                    </ListItemButton>
                </ListItem>
                {recentConversations.map((conversation) => {
                    // Create a new closure for each conversation item
                    return (
                        <ListItem key={conversation.sessionId} disablePadding sx={{ display: 'block', pl: 2, pr: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '10px' }}>
                                <ListItemButton
                                    onClick={() => {
                                        setChatSessionId(conversation.sessionId);
                                        setShowWelcome(false);

                                    }}
                                    selected={conversation.sessionId === chatSessionId}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: 8,
                                        padding: '10px',
                                        '&:hover': {
                                            backgroundColor: theme.palette.action.hover,
                                        },
                                        backgroundColor: conversation.sessionId === chatSessionId ? theme.palette.action.selected : 'transparent',
                                        // Adjust the width to leave space for the icon
                                        overflow: 'hidden', // Hide any content that overflows
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <ChatBubbleOutlineOutlinedIcon fontSize='small' sx={{ mr: 1 }} />
                                        <Typography variant="subtitle2" noWrap>
                                            {conversation.title || `Chat ${conversation.sessionId.slice(-5)}`}
                                        </Typography>
                                    </Box>
                                </ListItemButton>
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    data-sessionid={conversation.sessionId}
                                    onClick={(event) => handleClick(event)} // Pass the event to handleClick
                                    sx={{
                                        padding: 0,
                                        '&:hover': {
                                            backgroundColor: 'transparent', // Remove background on hover
                                            color: theme.palette.primary.main, // Change color on hover
                                        },
                                    }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            </Box>
                            {/* Context menu */}
                        </ListItem>
                    );
                })}
                {/* 'Show More' Button */}
                {
                    conversationsWithMessages.length > 5 && ( // Show only if there are more than 5 conversations
                        <ListItem disablePadding key='showMore'>
                            <Button
                                onClick={toggleShowMore}
                                endIcon={<ExpandMoreIcon />}
                                sx={{
                                    color: theme.palette.text.secondary,
                                    textTransform: 'none',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                {showMore ? 'Show Less' : 'Show More'}
                            </Button>
                        </ListItem>
                    )
                }
                {/* Menu outside the map loop */}
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiMenuItem-root': {
                                '&:focus': {
                                    backgroundColor: theme.palette.action.hover,
                                },
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={() => handleMoreInfo(selectedSessionId)}> {/* Pass sessionId to handleMoreInfo */}
                        <InfoIcon fontSize='small' sx={{ mr: 1 }} /> More Info
                    </MenuItem>
                    <MenuItem onClick={() => {

                        handleDelete(selectedSessionId)
                    }}> {/* Pass sessionId to handleDelete */}
                        <DeleteIcon fontSize='small' sx={{ mr: 1 }} /> Delete
                    </MenuItem>
                </Menu>
                {/* Modal for More Info */}
                <Modal
                    open={openModal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Card
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 16, // Higher elevation
                            p: 4,
                            borderRadius: '10px',
                        }}
                    >
                        <CardHeader
                            action={
                                <IconButton aria-label="close" onClick={handleModalClose}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            title={
                                // Display the title of the selected conversation
                                conversationsWithMessages.find(
                                    (conv) => conv.sessionId === selectedSessionId
                                )?.title || 'Chat Details'
                            }
                            titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
                            sx={{
                                // Add some spacing below the title
                                marginBottom: '1rem',
                            }}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        sx={{ color: theme.palette.text.secondary }} // Lighter color
                                    >
                                        Creation Date:
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <CalendarTodayIcon sx={{ marginRight: '0.5rem' }} />
                                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                            {selectedConversationStats.creationDate}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        sx={{ color: theme.palette.text.secondary }} // Lighter color
                                    >
                                        Number of Chats:
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'left' }}>
                                        <ChatBubbleIcon sx={{ marginRight: '0.5rem' }} />
                                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                                            {selectedConversationStats.chatCount}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        sx={{ color: theme.palette.text.secondary }} // Lighter color
                                    >
                                        Tokens:
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TokenIcon sx={{ marginRight: '0.5rem' }} />
                                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                                            {selectedConversationStats.tokenCount}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Modal>
            </List>
        )
    );
}

export default ConversationsList;