/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useCallback, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook
import { IconButton, Button } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



export const usePrevNextButtons = (emblaApi) => {
    const theme = useTheme();
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollPrev()
    }, [emblaApi])

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollNext()
    }, [emblaApi])

    const onSelect = useCallback((emblaApi) => {
        setPrevBtnDisabled(!emblaApi.canScrollPrev())
        setNextBtnDisabled(!emblaApi.canScrollNext())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onSelect(emblaApi)
        emblaApi.on('reInit', onSelect).on('select', onSelect)
    }, [emblaApi, onSelect])

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    }
}

export const PrevButton = ({ disabled, onClick, ...props }) => {
    const theme = useTheme();
    const { children, ...restProps } = props

    return (
        <IconButton // Use IconButton instead of a regular button
            className="embla__button embla__button--prev"
            disabled={disabled}
            onClick={onClick}
            sx={{ // Style the IconButton directly
                borderRadius: '50%', // Make it circular
                backgroundColor: 'transparent',
                color: theme.palette.text.secondary, // Default icon color
                transition: 'background-color 0.3s, color 0.3s',  // Smooth transition
                '&:hover': {
                    backgroundColor: theme.palette.primary.main, // Use your theme's primary color
                    color: theme.palette.getContrastText(theme.palette.primary.main), // Ensure contrast
                },
                '&:disabled': {
                    backgroundColor: 'transparent',
                    color: theme.palette.text.disabled, // Or any suitable color for disabled state
                },
            }}
        >
            <ArrowBackIosIcon />
        </IconButton>
    )
}

export const NextButton = ({ disabled, onClick, ...props }) => {
    const theme = useTheme();
    const { children, ...restProps } = props

    return (
        <IconButton // Use IconButton instead of a regular button
            className="embla__button embla__button--next"
            disabled={disabled}
            onClick={onClick}
            sx={{ // Style the IconButton directly
                borderRadius: '50%', // Make it circular
                backgroundColor: 'transparent',
                color: theme.palette.text.secondary, // Default icon color
                transition: 'background-color 0.3s, color 0.3s',  // Smooth transition
                '&:hover': {
                    backgroundColor: theme.palette.primary.main, // Use your theme's primary color
                    color: theme.palette.getContrastText(theme.palette.primary.main), // Ensure contrast
                },
                '&:disabled': {
                    backgroundColor: 'transparent',
                    color: theme.palette.text.disabled, // Or any suitable color for disabled state
                },
            }}
        >
            <ArrowForwardIosIcon />
        </IconButton>

    )
}
